@import '~theme/variables';

.section {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: $space-m $space-m;

  @include breakpoint(sm) {
    min-height: 36rem;
    justify-content: center;
    padding: $space-xl 10%;
  }

  @include breakpoint(md) {
    min-height: 40rem;
  }

  @include breakpoint(lg) {
    min-height: 44rem;
  }

  &.yellow {
    background-color: $color-primary;
    &:after {
      background-image: url('../../../../../../public/static/images/shared/header-stripes-primary.svg');
    }
  }

  &.softPeach {
    background-color: $color-soft-peach;
    &:after {
      background-image: url('../../../../../../public/static/images/shared/header-stripes-peach.svg');
    }
  }

  &.softSand {
    background-color: $color-soft-sand;
    &:after {
      background-image: url('../../../../../../public/static/images/shared/header-stripes-sand.svg');
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -22px;
    left: 0;
    right: 0;
    height: 22px; // original height of the image
    background-size: cover;
    background-repeat: no-repeat;
    z-index: $z-index-above;
  }
}

.row {
  @include breakpoint(md, max) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: $space-m;
}

.title {
  font-family: $font-family-display;
  font-size: 7.9rem;
  letter-spacing: -0.015em;
  line-height: 6.7rem;
  color: $color-neutral-01;

  @include breakpoint(sm, max) {
    font-size: 4.8rem;
    line-height: 4rem;
  }
}

.mobileTitle {
  @include breakpoint(sm) {
    display: none;
  }
}

.desktopTitle {
  @include breakpoint(sm, max) {
    display: none;
  }
}

.imageWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include breakpoint(md, max) {
    margin-bottom: $space-l;
    justify-content: center;
  }

  img {
    width: 100%;
    max-width: 52.5rem;
  }
}

.subtitle {
  font-size: 2rem;
  color: $color-neutral-01;
  font-weight: 700;
  line-height: 3.2rem;
}

.description {
  p {
    font-size: 1.8rem;
    line-height: 28.8px;
  }
}

.usps {
  display: flex;
  gap: $space-s;
  flex-direction: column;
}

.usp {
  display: flex;
  gap: $space-xs;
  align-items: center;

  svg {
    font-size: 2.4rem;
  }

  p {
    font-size: 1.8rem;
    line-height: 28.8px;
  }
}

.buttons {
  display: flex;
  gap: $space-s;
  @include breakpoint(md, max) {
    flex-direction: column;
  }
}

.primaryButton {
  &:hover {
    background-color: transparent;
  }
}

.secondaryButton {
  background-color: transparent;
}
